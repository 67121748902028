
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('li', { 'className': 'item grid__item' }, _createElement('div', { 'className': 'card-wrapper underline-links-hover' }, _createElement('a', {
                    'href': this.url,
                    'className': 'card card--standard card--media'
                }, _createElement('div', { 'className': 'card__inner gradient' }, _createElement('div', { 'className': 'card__media' }, _createElement('div', { 'className': 'media media--transparent media--hover-effect' }, _createElement('img', {
                    'className': 'motion-reduce',
                    'data-sizes': 'auto',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })))), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__information' }, this.custom_field_product_brand_logo ? _createElement('span', {
                    'className': 'brand-logo',
                    'key': '602'
                }, _createElement('a', { 'href': this.vendor_url }, _createElement('img', {
                    'src': this.custom_field_product_brand_logo,
                    'alt': this.vendor
                }))) : null, _createElement('h3', { 'className': 'card__heading h5' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'full-unstyled-link'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'card-information' }, _createElement('div', { 'className': 'caption-with-letter-spacing light' }, _createElement('span', {}, 'Part #'), _createElement('span', { dangerouslySetInnerHTML: { __html: this.sku } })), this.free_shipping ? _createElement('span', {
                    'className': 'tag_free',
                    'key': '1244'
                }, _createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'width': '25.746',
                    'height': '19.201',
                    'viewBox': '0 0 25.746 19.201'
                }, _createElement('path', {
                    'id': 'delivery',
                    'd': 'M86.348,83.779h13.719V97.5H94.5a3.066,3.066,0,0,0-5.919,0H86.349Zm5.191,15.982a1.459,1.459,0,1,0-1.032-.427A1.459,1.459,0,0,0,91.539,99.761Zm13.384-12.634,3.953,3.956v1.071h-7.2V87.127ZM108.144,97.5a3.067,3.067,0,0,0-5.92,0h-.547V93.762h7.2V97.5Zm-2.96,2.263a1.459,1.459,0,1,0-1.032-.427A1.459,1.459,0,0,0,105.184,99.761ZM86.3,99.107H88.58a3.067,3.067,0,0,0,5.919,0h7.726a3.067,3.067,0,0,0,5.92,0h1.537a.8.8,0,0,0,.8-.8V90.748a.8.8,0,0,0-.236-.568l-4.424-4.426a.806.806,0,0,0-.569-.237h-3.579V83.728a1.562,1.562,0,0,0-1.559-1.559H86.3a1.562,1.562,0,0,0-1.559,1.559v13.82A1.561,1.561,0,0,0,86.3,99.107Z',
                    'transform': 'translate(-84.74 -82.169)',
                    'fillRule': 'evenodd'
                })), '\n              free ground shipping\n            ') : null, _createElement('div', { 'className': 'price' + (this.on_sale ? ' price--on-sale' : '') }, _createElement('div', { 'className': 'price__container' }, !this.compare_at_price ? _createElement('div', {
                    'className': 'price__regular',
                    'key': '2338'
                }, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price))) : null, this.compare_at_price ? _createElement('div', {
                    'className': 'price__sale',
                    'key': '2538'
                }, _createElement('s', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.compare_at_price)), _createElement('span', { 'className': 'price-item price-item--last price-item--sale' }, '\n                    ', this.formatPrice(this.price), '\n                  ')) : null))), _createElement('div', { 'className': 'quick-add no-js-hidden' }, this.variant_ids.length === 1 ? _createElement('product-form', { 'key': '2994' }, _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'acceptCharset': 'UTF-8',
                    'className': 'form',
                    'encType': 'multipart/form-data',
                    'noValidate': 'novalidate',
                    'data-type': 'add-to-cart-form'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'form_type',
                    'value': 'product'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'utf8',
                    'value': '\u2713'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids[0]
                }), _createElement('button', {
                    'type': 'submit',
                    'name': 'add',
                    'className': 'quick-add__submit button button--full-width button--secondary',
                    'data-sold-out-message': 'true',
                    'disabled': this.out_of_stock
                }, !this.out_of_stock ? _createElement('span', { 'key': '3636' }, 'Add to cart') : null, !!this.out_of_stock ? _createElement('span', {
                    'className': 'sold-out-message',
                    'key': '3706'
                }, 'Sold out') : null), _createElement('input', {
                    'type': 'hidden',
                    'name': 'product-id',
                    'value': this.variant_ids[0]
                }))) : null, this.variant_ids.length > 1 ? _createElement('a', {
                    'className': 'cm_choose-option quick-add__submit button button--full-width button--secondary',
                    'href': this.url,
                    'key': '3956'
                }, '\n              Choose options\n            ') : null), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '41770'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null]), _createElement('div', { 'className': 'card__badge bottom left' }, this.on_sale ? _createElement('span', {
                    'className': 'badge badge--bottom-left color-accent-2',
                    'key': '4645'
                }, 'Sale') : null), this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '4755'
                }) : null))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'grid cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []