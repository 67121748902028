import shopifyDawnGenericDefaults from '../shopify-dawn-generic/config.js';

const defaultHandleCurrencies = shopifyDawnGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchBox',
).onDropdownItemsReceived;

export default {
  includes: ['shopify-dawn-generic'],
  ...shopifyDawnGenericDefaults,
  SearchRequestDefaults: {
    ...shopifyDawnGenericDefaults.SearchRequestDefaults,
    sort: window.location.pathname.includes('/collections') ? 'title' : 'relevance',
  },
  product: {
    ...shopifyDawnGenericDefaults.product,
    dropdownImageWidth: 200,
    dropdownImageHeight: 200,
  },
  Widgets: [
    ...shopifyDawnGenericDefaults.Widgets.filter(
      (w) => !['SearchBox', 'SearchBoxDialogButton'].includes(w.name),
    ),
    {
      name: 'SearchBox',
      location: {
        selector: '.header__search#header__search_1 .search-modal__content',
        class: 'search-modal__form',
      },
      template: 'SearchBox',
      onDropdownItemsReceived: () => defaultHandleCurrencies(),
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
    },
    {
      name: 'SearchBoxDialogButton',
      location: '.header_top .mob_search',
      template: 'searchBox/dialogButtonMobile',
      onDropdownItemsReceived: () => defaultHandleCurrencies,
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
    },
    {
      name: 'MobileMenuSearchBox',
      type: 'SearchBoxDialogButton',
      location: {
        selector: '.header__search#header__search_2 .search-modal__content',
        class: 'search-modal__form',
      },
      template: 'searchBox/dialogButtonAdaptable',
      onDropdownItemsReceived: () => defaultHandleCurrencies,
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
    },
    {
      name: 'HeaderVehicleWidget_sidebar',
      type: 'VehicleWidget',
      location: '#cm-ymm-sidebar',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: !!window.Convermax.config?.ymmInitCollapsed,
      isAlwaysColumnLayout: true,
    },
  ],
};
