
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide header__icon header__icon--search link focus-inset cm_searchBoxDialogButton' }, _createElement('svg', {
                'className': 'icon icon-search',
                'aria-hidden': 'true',
                'focusable': 'false'
            }, _createElement('path', {
                'className': 'path1',
                'fillRule': 'evenodd',
                'clipRule': 'evenodd',
                'd': 'M11.03 11.68A5.784 5.784 0 112.85 3.5a5.784 5.784 0 018.18 8.18zm.26 1.12a6.78 6.78 0 11.72-.7l5.4 5.4a.5.5 0 11-.71.7l-5.41-5.4z',
                'fill': 'currentColor'
            })));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]